import React from "react";
import styles from "../styles/components/table.module.scss";
import Checkbox from "react-custom-checkbox";
import Button from "./Button";
import SmallButton from "./SmallButton";

export function Tr({
  tr,
  handleCheckbox,
  trId,
  editRow,
  setEditRow,
  Filters,
  handleOnSave,
  handleEditText,
  handleOnDelete,
  handleShow,
  th,
  notEditable,
}) {
  const handleEditButton = (id) => {
    setEditRow(id);
  };

  const handleDelete = () => {
    handleOnDelete();
  };

  const handleSave = () => {
    handleOnSave();
  };

  const handleCancel = () => {
    setEditRow(null);
  };

  if (editRow?.id !== trId) {
    return (
      <tr className={styles.tr}>
        <td className={styles.td}>
          <Checkbox
            checked={tr.checked}
            borderColor={tr.checked ? "#FFD274" : "#B4B4B4"}
            className={styles.checkBox}
            icon={<div className={styles.checkDiv} />}
            onChange={() => handleCheckbox(tr.id)}
          />
        </td>
        {Object.keys(tr)
          .slice(2, th?.length)
          .map((key, index) => (
            <td key={index} className={styles.td}>
              {tr[key]}
            </td>
          ))}
        <td className={styles.td} style={{ display: "flex" }}>
          {handleShow && (
            <Button
              action={() => {
                handleShow();
                setEditRow(tr);
              }}
              text="Show"
              type="view"
            />
          )}

          <Button
            action={() => handleEditButton(tr)}
            text={tr.button?.text}
            type={tr.button?.type}
          />
        </td>
      </tr>
    );
  } else {
    return (
      <tr className={styles.tr}>
        <td className={styles.td} colSpan="100%">
          <div className={styles.cardDropDowns}>{Filters}</div>
          {/* <div className={styles.textAreaContainer}>
            <div className={styles.placeholder}>
              <img src={quoteIcon} alt="" />
              Text:
            </div>
            <textarea onChange={handleChange} />
          </div> */}
          <div className={styles.buttonContainer}>
            {editRow?.button?.type !== "view" &&
              handleOnSave &&
              !notEditable && (
                <SmallButton
                  type="primary"
                  text={tr.button.text}
                  onClick={handleSave}
                />
              )}

            <SmallButton type="default" text="Cancel" onClick={handleCancel} />
            {handleOnDelete && (
              <SmallButton type="danger" text="Delete" onClick={handleDelete} />
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default function Table({
  tableData,
  setTableData,
  editRow,
  setEditRow,
  Filters,
  handleEditText,
  handleShow,
  handleOnSave,
  handleOnDelete,
  notEditable,
}) {
  const handleCheckbox = (id) => {
    const trObject = tableData.td.find((tr) => tr.id === id);
    if (!trObject) return;

    const index = tableData.td.indexOf(trObject);
    tableData.td[index].checked = !tableData.td[index].checked;
    setTableData({ ...tableData });
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead className={styles.tr}>
          <tr>
            {tableData.th.map((th, index) => (
              <th
                key={index}
                className={
                  index === 1
                    ? `${styles.longThUserTable} ${styles.th}`
                    : styles.th
                }
              >
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.td.map((tr, index) => (
            <Tr
              tr={tr}
              th={tableData.th}
              handleCheckbox={handleCheckbox}
              key={index}
              trId={tr.id}
              tanleData={tableData}
              setTableData={setTableData}
              editRow={editRow}
              setEditRow={setEditRow}
              Filters={Filters}
              handleEditText={handleEditText}
              handleOnSave={handleOnSave}
              handleShow={handleShow}
              handleOnDelete={handleOnDelete}
              notEditable={notEditable}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
