import React, { useEffect, useState } from "react";
import styles from "../styles/cards/showMatchUsers.scss";
import LoadingAnim from "../components/LoadingAnim";
import Notification from "../components/Notification";

export default function VideoPlayer({ updateTrigger, setUpdateTrigger, data }) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={setUpdateTrigger}>
          Close
        </span>
        <span className="modal-header">Watch Recording</span>
        <video
          className="video-content"
          src="https://duel-cube-image.s3.us-east-2.amazonaws.com/sq3y-g1nz-3pz6/2024-08-16T16%3A43%3A00.280Z?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXVzLWVhc3QtMiJHMEUCIQCuRUJNy%2FZLG1E3Dupr0PWZXi7TxNJKylm90d02RRyqLAIgeRktHxyrkxaPkT%2FdKoAG02z16K4Laal7NFg%2BmAKga4Aq7QIInv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyOTM4MzU4NTgwNTgiDGWSpbJb0MdqHh60ASrBAqNSa%2BwwHQriC6Eox0JWNH2Ia%2F6gIgwXsM%2FDBlCCbui6dHn7JSAoxWjfuC42gYqR%2Bf4R%2BZpP%2FLBrALL2R5zsVvJaDrh0lUkKP1DIMZ7eWqrVLmPdlty%2Bx9hyaoJl%2BjQubTfA8uWfMDBNl%2BE2yqlX0O3LNbdfxYVG2BkoVJH0t%2FwT%2FBkYAccmhoBAO94x01WXGoqLxhKziTJrTR7cArQrDpfy%2BtsRK0mRVDTwAEJE%2BDcAXPd33tcYsmfT%2Fp3kXd%2Fr1K6Blj1eljXw4VvwI0mZoef71oJPkNeHF0fufGirpoqbHiV%2Bi0Ii66C2BVHh19sz7Sl7j%2FB6k6KUFj%2Bx87EzVUrgFKlC%2BztEoXw67VG4ts5D14qr%2Bjwtjtaq4TQudBOkwTejqYtzl8HUuCJERDIuURdk0m2oMfx%2BLBTxSSkm3p38oDCq8Ym3BjqzAs5C5GccXaGqiZmTsEKCqtd4dJ2O2BqrPnzqBOC%2FBC0lev9jzIpyzVRX60aLloZ0cd9Eho9vso7a0%2BYu9ulf68cNyXEfST9Wjgx9SxGxXyJMWN3X2nruPKQw8Txny5qKxtn%2BybZq%2FLp6KUly250DnTXJs41oCumj28eOHi%2BUsc3sF6aGSUDOp7kRFAsqzTZEZX5IJtpVxaWACNudqk3zUBXjTtXDHQZ14DMobSsESV1%2BcVunUpeQgQEvgW%2F8NH7GzSEdEijv%2B7VaKAx95QYL7sG5yr8gogIjRvZ%2BW9yAYHs4d4Wdt95k4PQ2pSgzMkmXqLhqApLDNId6VukfBXURU%2FHB2Fok4DetCfjOJnvx4dR%2FNY3of%2FVpYKIiTXs%2B4KfsiqHicpM2OgQAE1uA8yO1QaihYQk%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240912T073158Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=ASIAUI2P3MCFOMY5YV3I%2F20240912%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Signature=6ea792dc431b40bc58b6bb92f61531c226b4881853793b1a8b52d4068fb908ce"
          controls={true}
          autoPlay
          onError={(e) => console.error("Video error: ", e)}
        />
        <LoadingAnim loading={loading} />
        <Notification data={notification} />
      </div>
    </div>
  );
}
