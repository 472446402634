import React, { useEffect, useState } from "react";
import styles from "../styles/cards/showMatchUsers.scss";
import api from "../api/api";
import NewTable from "./Table";
import LoadingAnim from "../components/LoadingAnim";
import SmallButton from "../components/SmallButton";
import Notification from "../components/Notification";

export default function ShowMatchUsers({
  updateTrigger,
  setUpdateTrigger,
  data,
  type,
  visible,
  handleLive,
}) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [tableData, setTableData] = useState({
    th: [
      "Player",
      "ActivitionID",
      "EaID",
      "XboxId",
      "EpicID",
      "StreetfighterID",
      "PlayStationID",
      "TwitchID",
      "Live",
      "AccountStatus",
      "Refund",
    ],
    td: [],
  });
  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let resData;
        let mergedArray = [];
        if (type === "TOURNAMENT") {
          resData = await api.getTournamentUsersData(data.id);
          mergedArray = resData.players.map((player) => player.user);
        } else {
          resData = await api.getMatchUsersData(data.id);
          const res = resData?.matchDetails;
          mergedArray = [...res.team1, ...res.team2];
        }
        setPlayerList(mergedArray.map((user) => user._id));
        const newTableData = mergedArray.map((user) => ({
          Player: user?.username || "N/A",
          ActivitionID: user?.activitionId || "N/A",
          EaID: user?.eaId || "N/A",
          XboxId: user?.xboxId || "N/A",
          EpicID: user?.epicId || "N/A",
          StreetfighterID: user?.streetfighterId || "N/A",
          PlayStationID: user?.platstationId || "N/A",
          TwitchID: user?.twitchId || "N/A",
          Live: `Streamer/${
            resData?.matchStreams?.find((stream) => stream?.userId === user._id)
              ? resData?.matchStreams?.find(
                  (stream) => stream?.userId === user._id
                )?._id
              : "No Live"
          }&${
            resData?.matchStreams?.find((stream) => stream?.userId === user._id)
              ? resData?.matchStreams?.find(
                  (stream) => stream?.userId === user._id
                )?.streamType
              : ""
          }`,
          accountStatus: user?.accountStatus,
          button: "Refund",
          userID: user._id,
        }));

        setTableData({
          th: [...tableData.th],
          td: newTableData,
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching match users data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [data, visible]);

  const handleRefund = async (userData) => {
    setLoading(true);

    try {
      const res = await api.refundAll({
        eventId: data?.id,
        userId: userData.userID,
      });
      console.log(res);
      if (!res.status) {
        setNotification({
          type: "success",
          text: res.message,
        });
      } else {
        setNotification({
          type: "error",
          text: res.message,
        });
      }
    } catch (error) {
      console.log(`Fail to refund - ${userData.userID}`);
    }

    setLoading(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={setUpdateTrigger}>
          Close
        </span>
        <span className="modal-header">
          Players of -{" "}
          {type === "TOURNAMENT" ? data.tournamentId : data.matchId}{" "}
        </span>
        <NewTable
          tableData={tableData}
          setTableData={setTableData}
          handleClick={handleRefund}
          handleLive={handleLive}
        />
        <LoadingAnim loading={loading} />
        <Notification data={notification} />
      </div>
    </div>
  );
}
